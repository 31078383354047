@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,li {
  list-style: none;
}

.App {
  position: relative;
}
.AppAuth{
  padding: 20px 0;
}
.menu{
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.menu li{
  margin-left: 20px;
}
.btn-style{
  padding: 5px 5px 5px 5px;
  background-color: #f92742;
  border-radius: 25px;  
  line-height: 32px;
  text-align: center;
}

#home h2{
  font-family: 'Archivo', sans-serif !important;
  font-weight: 400;
  font-size:17px;
  max-width:415px;
}

.menu li a{
    min-width: 140px;
    font-size: 16px;    
    border: none;
    border-radius: 25px;
    color: white;
    font-weight: 600;
    cursor: pointer;    
    color: white;
    border-radius: 20px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 0px;
}
.button-web-app-sign{
  background-color:transparent !important;
  font-family: 'Archivo', sans-serif !important;
}
#langSelector{
  font-family: 'Archivo', sans-serif !important;
}
button{
  padding: 12px 25px;
  background: #4EB7A1;
  border: none;
  border-radius: 25px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  padding: 16px 30px;
  min-width: 140px;
  font-size: 16px;
  background: #4EB7A1;
  border: none;
  border-radius: 25px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  background-color: #f92742;
  color: white;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
}
.popupWrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #71707087;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.popupContent{
  max-width: 700px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 3;
}
.popupContent header a{
  display: none;
}
.myAccWrapper {
  padding-top: 100px ;
}

.msd__account-wrapper {
  height: calc(100vh - 100px) !important;
}
header{background-color:transparent !important;margin-top:0px !important;}
body{margin-top:-100px;}

#home{
  background-image: url(/static/media/intro.948c3c08.jpg);
}

#upcoming{
  background-image:url(/static/media/slide1-desktop.9341cfee.png);
  background-size: cover;
  min-height: 100%;
  background-position:80%;
}
.poster-home{width: 100%;height:auto;}
.lisging-image-home{display: inline-block;}
#upcoming .main-title{
  margin-top:10%;  
  max-width: 740px;
  color: #fff;
  font-size: 3.4rem;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -2px;
  text-transform: capitalize;
}

#upcoming .listing-image{
  margin-top:17%;
}

#upcoming h4{  
  max-width:660px;
  font-family: 'Archivo', sans-serif !important;
  font-weight: 400;
  font-size:30px;
}


@media (max-width:1300px) {
  #upcoming {
    background-position: 100%;
  }
}

@media (max-width:1200px) {
  #upcoming .main-title{margin-top:600px;}
  #upcoming h4{font-size:20px;}
  
}
@media (max-width:1024px) {
  #upcoming {    
    background-image:url(/static/media/slide1.bff07fe7.png);  
  }
}
@media (max-width:768px) {
  #upcoming {    
    background-image:url(/static/media/slide1.bff07fe7.png);
    background-size: cover;
    min-height: 800px;
    max-height: 1400px;
    background-position: 50%;
  }

  #upcoming .main-title{margin-top:0%;max-width:300px;}
  .listing-image .col-md-3{width:48%;}
  #upcoming h4{margin-top:105% !important;text-align:center;}
  .listing-image{margin-top:0px !important;}

  .btn-black{font-size:12px;}
}

#lives .listing-image{text-align: center;margin-top:10%;}


#lives{
  background-image:url(/static/media/slide2-desktop.64756f4c.png);
  background-size: cover;
  min-height: 100%;
  background-position: 100%;
}

#lives .main-title{
  margin-top:8%;  
  max-width: 820px;
  color: #fff;
  font-size: 3.8rem;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -2px;
  text-transform: capitalize;
}
#lives h4{
  font-family: 'Archivo', sans-serif !important;
  max-width:780px;
  font-weight: 400;
    font-size: 24px;
    margin-top:30px;
}

@media (max-width:1200px) {
  #lives .main-title{margin-top:500px;}
}

@media (max-width:868px) {
  .poster-wrapper{max-width:275px;}
}

@media (max-width:768px) {
  #lives {    
    background-image:url(/static/media/slide2.99143f2e.png);
    background-size: cover;
    min-height: 800px;
    max-height: 1400px;
    background-position: 50%;
  }

  #lives .main-title{
    margin-top:0%;
    font-size:36px !important;
  }
  #lives h4{margin-top:80%;text-align: center;font-size:15px;}
}

#arrivals {background-image:url(/static/media/slide3-desktop.22665528.png);
background-size: cover;
min-height: 100%;
background-position: 100%;
}

#arrivals{
  min-height: 1080px;
}
#arrivals .main-title{
  font-family: 'Archivo', sans-serif !important;
  margin-top:20%;  
  max-width: 660px;
  color: #fff;
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 300;
  letter-spacing: -2px;
  text-transform: uppercase;
}

#arrivals .main-title-2{
  
  margin-top:0px;  
  margin-bottom:20px;
  max-width: 660px;
  color: #fff;
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -2px;
  text-transform: uppercase;
}

#arrivals h4{
  font-family: 'Archivo', sans-serif !important;
  max-width: 720px;
  font-size:26px;

}

@media (max-width:1024px) {
  .btn-style{display:none;}
}

@media (max-width:1400px) {
  #arrivals {    
    background-image:url(/static/media/slide3.bc5e907b.png);
    background-size: cover;
    min-height: 1080px;
    max-height: 1400px;
    background-position: 50%;
  }
  #arrivals .main-title{
    margin-top:80%;
  }
  
}


#devices {
  background-image:url(/static/media/slide4-desktop.a04585ba.png);
  background-size: cover;
  min-height: 100%;
  background-position:100%;
  }

#devices .main-title{
  margin-top:15%;  
  max-width: 725px;
  color: #fff;
  font-size: 4.2rem;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -2px;
  text-transform: capitalize;
}

#devices h3.desktop-only{
  max-width: 760px;
}

#devices h4{  
  font-family: 'Archivo', sans-serif !important;
  max-width: 720px;
  font-size:24px;
  font-weight:300;
  margin-top:20px;
}

@media (max-width:1200px) {
  .listing-img-device{max-width: 660px;}
}

@media (max-width:1024px) {
  #devices {    
    background-image:url(/static/media/slide4.f1e152c8.png);
    background-size: cover;
    min-height: 1440px !important;
    max-height: 1680px;
    background-position: 50%;
  }
  #devices .main-title{
    margin-top:0% !important;
    font-size:3.6rem;
  }
  #devices h4{margin-top:100%;
    font-size: 22px;
    margin-top:0px;
  }
  
}




#subscribe {
  background-image:url(/static/media/slide5-desktop.cf673f62.png);
  background-size: cover;
  min-height: 100%;
  }

  
#subscribe .main-title{
  font-family: 'Archivo', sans-serif !important;
  text-align:center;
  margin-top:20%;  
  max-width: 880px;
  margin-left:auto;
  margin-right:auto;
  color: #fff;
  font-size: 3rem;
  line-height: 1;
  font-weight: 300;
  letter-spacing: -2px;
  text-transform: capitalize;
  margin-bottom:30px;
}
#subscribe h4{
  font-family: 'Archivo', sans-serif !important;
max-width: 660px;
margin-left: auto;
margin-right: auto;
text-align: center;
margin-top: 30px;
margin-bottom:30px !important;
line-height: 30px;
}

#subscribe  .main-title-sub{
  margin-top:20px;
  margin-bottom:20px;
  margin-bottom:30px !important;
}

@media (max-width:768px) {
  #subscribe {    
    background-image:url(/static/media/slide5.55e9870a.png);
    background-size: cover;
    min-height: 800px;
    max-height: 1400px;
    background-position: 50%;
  }
}


#subscribe .main-title-sub{
  text-align:center;  
  max-width: 800px;
  margin-left:auto;
  margin-right:auto;
  color: #fff;
  font-size: 3.0rem;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -2px;
  text-transform: capitalize;
}

.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 5px;}
.poster-wrapper{max-width: 330px;    margin-left: auto;  margin-right: auto;}

h1,h2,h3,h4,h5,h6{font-family: 'Archivo Black', sans-serif;}
a{font-family: 'Archivo Black', sans-serif !important;}
p{font-family: 'Archivo', sans-serif !important;}
span{font-family: 'Archivo Black', sans-serif !important;}

footer h5{font-family: 'Archivo', sans-serif !important;font-size:22px !important;}
footer span{font-family: 'Archivo', sans-serif !important;}

/* Style the header */
#header {
  padding: 10px 16px;
  background: #555;
  color: #f1f1f1;
}

/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 102px;
}


@media (max-width:768px) {
  .btn-red{font-size:9px;}
  h3{font-size:2em !important;}
}


@media (max-width:300px) {
  .btn-red{font-size:14px;}
  h1{font-size:1.5em !important;}
  h3{font-size:1.5em !important;}
}


@media (max-width:500px) {
  
  #langSelector{display:none !important;}
  #webapplinksignup{margin-bottom:0px;font-size:14px !important;}
  .menu{margin-bottom:0px;}
  .menu li{margin-bottom:0px !important;}

}

#header{
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width:900px){
  #header{
    padding-left: 50px;
    padding-right: 50px;
  }
  .menu .mobile-li{    display: inline !important;}
  .btn-lang{display:none !important;}
  .btn-sign-in{margin-right:10px !important;}
  .menu li{margin-bottom:0px !important;}
  .menu{margin-bottom:0px;}
}
@media (max-width:600px){
  #header{
    padding-left: 20px;
    padding-right: 20px;
  }
  .btn-lang{display:none !important;}
  #langSelector{display:none !important;}
  .btn-red{text-align:center;}
}

.tv-mobile{
  width: 100%;
  height:auto;
  display:none;
  max-width:600px;
}


.desktop-only{display:block;}
.mobile-only{display:none;}

@media (max-width:1024px){
  .tv-mobile{display:inline;margin-bottom:20px;}
  .desktop-only{display:none;}
  .mobile-only{display:inline;}
}

.hero{min-height: 1080px;}

.mobile-only{
  
  height: 120px;  
  
}


.listing-img-device{  
  display: block;
  margin-top:10px;
}
.single-img-device{  
  display: inline-block;
  margin-right:10px;
  margin-bottom:10px;
}

@media (max-width:1200px){
  .mobile-only{  
    width:100%;
    min-height:120px;  
    height: auto;  
  }
  .mobile-only .listing-img-device{  
    display: block;
    margin-top:10px;
  }
  .mobile-only .single-img-device{  
    display: inline-block;
    margin-right:10px;
    margin-bottom:10px;
  }
}

.col-md-12{
  padding-left:25px;
  padding-right:25px;
}


.appTextSignIn{font-size:14px !important;}

@media (max-width:500px){
  #home h2{
    font-size: 14px;;
  }

  #home p{
    font-size:9px;
  }
}

.force-desktop-only{display:block !important;}
.force-mobile-only{display:none !important;}

@media (max-width:768px){
  .force-desktop-only{display:none !important;}
  .force-mobile-only{display:block !important;}
  
}
@media (max-width:1600px){
  #upcoming{background-position: 65%;}
  #upcoming .listing-image{margin-top:30%;}
  #lives{background-position: 65%;}
}

@media (max-width:1200px){
  #upcoming{background-position:90%;}
  #upcoming .listing-image{margin-top:10%;}
  #upcoming .force-desktop-only{display:none !important;}
  #upcoming .force-mobile-only{display:block !important;}
  #upcoming .force-mobile-only strong{font-family: 'Archivo Black', sans-serif !important;}
  #upcoming h4{text-transform: uppercase;}
  #lives{background-position: 80%;}
}

@media (max-width:500px){
  .logoHome{height:18px !important;}
}
@media (max-width:350px){
  .btn-red{font-size:8px;}

  
  #webapplinksignup{font-size:12px !important;}
  #upcoming .main-title{font-size:24px !important;}
  #upcoming h4{font-size:16px !important;margin-top:117% !important;}
  #lives .main-title{font-size:28px !important;}
  #lives h4{font-size:12px !important;margin-top:117% !important;}
}

@media (max-width:834px){
  #arrivals{min-height:600px}
}

@media (max-width:768px){
  #arrivals{min-height:600px}
  #arrivals .main-title{margin-top:-10% !important;margin-bottom:0px;}
  #arrivals .main-title-2{margin-bottom:10px;}
  #arrivals h4{font-size:12px;}
  .tv-mobile{max-width: 400px;}
  #arrivals h4{max-width:370px;}
}


#devices .desktop-only .listing-img-device{
  max-width: 620px;  
  margin-top:20px;
}
#devices .desktop-only .single-img-device{
  margin-right:24px;
}
.btn-link-app{display:none;}
.firetv{margin-top:7px;}
.amazonAppStore{margin-top:7px;}
.chromecast{margin-top:4px;}


@media (max-width:1600px){
  #devices{
    background-position: 100%;
    min-height:1080px;
  }
}

@media (max-width:1200px){
  #devices .main-title{
    margin-top:38%;
  }

}
@media (max-width:1024px){
  .mobile-only .listing-img-device{
    margin-top:1290px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .single-img-device img{height:25px;margin-right:15px;margin-top:20px;}
  .firetv{margin-top:27px !important;}
  .amazonAppStore{margin-top:35px !important;}
  .chromecast{margin-top:24px !important;}
  
}
@media (max-width:1000px){
  .mobile-only .listing-img-device{
    margin-top:1230px;
  }
}
@media (max-width:768px) {

}

@media (max-width:768px) {
  #devices {    
    min-height: 1180px !important;
    max-height: 1180px;
  }
  #devices .main-title{margin-top:-5% !important;max-width:300px !important;}
  .mobile-only .listing-img-device{
    margin-top:845px !important;
  }
  .mobile-only .single-img-device{
    height: 12px;
  }
  .mobile-only .single-img-device img{
    height: 12px;
    margin-right:5px;
  }
  .amazonAppStore{margin-top:28px !important;}
}

@media (max-width:300px) {
  #devices  .mobile-only .listing-img-device {
    margin-top: 285px !important;
  }
}



#mobile-lang{margin-top:10px;}

@media (max-width:900px) {
  #subscribe .main-title{
    font-size:40px;   
    margin-top: 55%; 
  }
  #subscribe .main-title-sub{
    font-size:40px;    
  }
}

@media (max-width:500px) {
  #subscribe .main-title{
    font-size:18px !important;   
    margin-top: 60%; 
    letter-spacing: 0px;    
    margin-bottom:10px !important;
  }
  #subscribe .main-title-sub{
    margin-top:10px !important;
    margin-bottom:10px !important;
    font-size:18px !important;   
    letter-spacing: 0px;
  }
  #subscribe .btn-black{        
    font-size:10px !important;   
  }
  #subscribe h4{
    
    font-size:12px !important;   
    line-height: 14px;;
  }

  #footer h5{font-size:16px !important;}
}

@media (max-width:500px){
  #devices {    
    min-height: 720px !important;
    max-height: 690px;
  }
  #devices .main-title{margin-top:-5% !important;max-width:300px !important;}
  .mobile-only .listing-img-device{margin-top:445px !important;}
}
@media (max-width:380px) {
  
  #devices .main-title{font-size:24px !important;}
  #devices .listing-img-device{margin-top:385px !important;}
  #subscribe .btn-subscribe a{font-size:8px !important;}
  #subscribe .main-title{font-size:20px !important;}
  #footer h5{font-size:12px !important;}
}

#mobile-lang a{font-family: 'Archivo', sans-serif !important;font-weight:300;}
#mobile-lang a{margin-left:0px;}

@media (max-width:1024px) {
  .darkHeader .btn-red #webapplinksignup{font-size:16px !important;}
}

@media (max-width:500px) {
  .darkHeader #header .btn-red #webapplinksignup{font-size:7px !important;}
  .darkHeader #header .btn-red {padding:5px 10px 5px 10px !important;margin-right:0px !important;}
  #webapplinksignup{font-size:7px !important;    vertical-align: text-bottom;}

}

@media (max-width:380px) {
  .darkHeader .btn-red #webapplinksignup{font-size:7px !important;}
  #webapplinksignup{font-size:7px !important;    vertical-align: middle !important;}
}

.poster-wrapper span{line-height:0.5em !important;}
